import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import LineChart from 'recharts/lib/chart/LineChart';
import Line from 'recharts/lib/cartesian/Line';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';
import firebaseApp from "../common/firebase";
import Grid from "@material-ui/core/Grid/Grid";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import MUIDataTable from "mui-datatables";
import { isNullOrUndefined } from "util";
import Typography from "@material-ui/core/Typography/Typography";
import Paper from "@material-ui/core/Paper/Paper";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Button from "@material-ui/core/Button/Button";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    head: {
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline"
    },
    header: {
        margin: theme.spacing.unit * 5
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

function SimpleLineChart(props) {

    const { data } = props;
    return (
        // 99% per https://github.com/recharts/recharts/issues/172
        <ResponsiveContainer width="99%" height={320}>
            <LineChart data={data}>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid vertical={false} strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Boardings" stroke="#04ADA9" />
                <Line type="monotone" dataKey="Kapazitäten" stroke="#FF6344" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="Buchungen" stroke="#2196f3" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
}

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: [
                {name: "25.4", Boardings: 10, Kapazitäten: 100, Buchungen: 12},
                {name: "26.4", Boardings: 12, Kapazitäten: 110, Buchungen: 12},
                {name: "27.4", Boardings: 31, Kapazitäten: 110, Buchungen: 23},
                {name: "28.4", Boardings: 12, Kapazitäten: 167, Buchungen: 23},
                {name: "29.4", Boardings: 12, Kapazitäten: 112, Buchungen: 24},
                {name: "30.4", Boardings: 12, Kapazitäten: 112, Buchungen: 24},
            ]
        };
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.reload()
        });
    };

    componentDidMount() {
        this.setState({
            loading: false
        });
    }

    render() {

        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.state.loading ? <div /> : (
                    <React.Fragment>


                        <div className={classes.head}>

                            <Typography variant={"h5"} className={classes.header} align={"center"}>Trend
                            seit </Typography>

                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="age-simple">Zeitraum</InputLabel>
                                <Select
                                    value={this.state.period}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'period',
                                        id: 'period',
                                    }}
                                >
                                    <MenuItem value={"7days"}>Last 7 days</MenuItem>
                                    <MenuItem value={"30days"}>Last 30 days</MenuItem>
                                    <MenuItem value={"lastMonth"}>Last month</MenuItem>
                                    <MenuItem value={"thisMonth"}>This month</MenuItem>
                                </Select>
                            </FormControl>
                        </div>


                        <SimpleLineChart data={this.state.data} />

                        <Typography variant={"h5"} className={classes.header} align={"center"}>Die nächsten Schritte</Typography>

                        <Grid container spacing={24}>
                            <Grid item xs>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            On-Boardings
                                    </Typography>
                                        <Typography component="p">
                                            Prüfen Sie laufende On-Boardings
                                    </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small">On-Boardings öffnen</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            Kapazitäten
                                         </Typography>
                                        <Typography component="p">
                                            Prüfen Sie die bereitgestellten Kapazitäten
                                    </Typography>
                                    </CardContent>
                                    <CardActions>
                                    <Button size="small">Kapazitäten öffnen</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            Buchungen
                                    </Typography>
                                        <Typography component="p">
                                            Sehen Sie laufende Buchungen ein
                                    </Typography>
                                    </CardContent>
                                    <CardActions>
                                    <Button size="small">Buchungen öffnen</Button>

                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>


                    </React.Fragment>

                )}
            </React.Fragment>
        )
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Dashboard)