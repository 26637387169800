import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from '../pages/Admin';

import {firebaseApp} from "../common/firebase";
import * as firebase from "firebase";


import firebaseui from "firebaseui";

const styles = theme => ({});

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.setState({loading: false});



        const uiConfig = ({
            signInSuccessUrl: '/',
            signInOptions: [
                firebase.auth.EmailAuthProvider.PROVIDER_ID
            ],
            tosUrl: '/terms',
            signInFlow: 'popup',
            privacyPolicyUrl: '/privacy'
        });


        const ui = new firebaseui.auth.AuthUI(firebaseApp.auth());

        ui.start("#firebaseui-auth-container", uiConfig)


    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentWillUnmount() {
    }

    render() {

        return (

            <React.Fragment>
                <div id="firebaseui-auth-container"></div>
            </React.Fragment>

        )
    }


}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Login)