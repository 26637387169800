import React from 'react';
import Dashboard from '../components/Dashboard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FlagIcon from '@material-ui/icons/Flag';
import HospitalIcon from '@material-ui/icons/LocalHospital';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import AccountIcon from '@material-ui/icons/AccountBalance';
import CalenderIcon from '@material-ui/icons/CalendarToday';
import LocationIcon from '@material-ui/icons/LocationCity';
import ConfirmationIcon from '@material-ui/icons/ConfirmationNumber';
import CancelIcon from '@material-ui/icons/Cancel';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import WarningIcon from '@material-ui/icons/Warning';
import BarChartIcon from '@material-ui/icons/BarChart';
import FingerPrintIcon from '@material-ui/icons/Fingerprint';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SubjectIcon from '@material-ui/icons/Subject';
import BuildIcon from '@material-ui/icons/Build';
import PeopleIcon from '@material-ui/icons/People';

export const menus = [
    {
        entries: [
            {
                primary: "Dashboard",
                icon: <DashboardIcon />,
                component: <Dashboard />
            }]
    },
    {
        title: "Praxen",
        entries: [
            {
                primary: "On-Boardings",
                icon: <HowToRegIcon />,
            },
            {
                primary: "Praxen",
                icon: <HospitalIcon />,
            },
            {
                primary: "Ärzte",
                icon: <PersonAddIcon />,
            },
            {
                primary: "Standorte",
                icon: <LocationIcon />
            },
            {
                primary: "Bankkonten",
                icon: <AccountIcon />
            },
            {
                primary: "Kapazitäten",
                icon: <CalenderIcon />
            },
            {
                primary: "Gebuchte Slots",
                icon: <EventAvailableIcon />
            },
            {
                primary: "Stornierte Slots",
                icon: <EventBusyIcon />
            }]
    },
    {
        title: "Patienten",
        entries: [
            {
                primary: "Buchungen",
                icon: <ConfirmationIcon />,
            },
            {
                primary: "Stornierungen",
                icon: <CancelIcon />,
            }, 
            {
                primary: "Bewertungen",
                icon: <StarHalfIcon />,
            },
            {
                primary: "Meldungen",
                icon: <WarningIcon />,
            }
        ]
    },
    {
        title: "Reports",
        entries: [
            {
                primary: "Finance",
                icon: <BarChartIcon />,
            },
            {
                primary: "Rechnungen",
                icon: <ReceiptIcon />,
            }]
    },
    {
        title: "Inhalte",
        entries: [
            {
                primary: "Texte",
                icon: <SubjectIcon />,
            },
            {
                primary: "Parameter",
                icon: <BuildIcon />
            },
            {
                primary: "Unerwünschter Text",
                icon: <FingerPrintIcon />
            },
            {
                primary: "Unerwünschte Bilder",
                icon: <FingerPrintIcon />
            }]
    },

    {
        title: "Organisation",
        entries: [
            {
                primary: "Mitarbeiter",
                icon: <PeopleIcon />,
            }]
    },
];