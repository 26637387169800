import * as firebase from "firebase";

const config = ({
    apiKey: "AIzaSyA8Z8dVQiTRJoRpx0X7GNwoY2MWaGlSros",
    projectId: "p0071-dev",
    authDomain: "p0071-dev.firebaseapp.com",
    storageBucket: 'gs://p0071-dev.appspot.com',
    databaseURL: 'https://p0071-dev.firebaseio.com',
});

export const firebaseApp = firebase.initializeApp(config);

export const firestore = firebaseApp.firestore();

firestore.settings({});

