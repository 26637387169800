import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Admin from './pages/Admin';
import Login from './pages/Login';
import Page404 from './pages/Page404';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MuiThemeProvider from "@material-ui/core/es/styles/MuiThemeProvider";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import {adminTheme} from "./pages/AdminTheme"

function AdminWrapper(props) {
    const { classes, theme } = props; // example
    return (
        <MuiThemeProvider theme={adminTheme}>
            <CssBaseline /> {/* initialize css */}
            <Admin />
        </MuiThemeProvider>
    );
}

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/" exact component={AdminWrapper} />
            <Route path="/login" component={Login} />
            <Route path="*" component={Page404} />
        </Switch>
    </BrowserRouter>,
    document.getElementById('root')
);