import React from 'react';
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";


import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {isNullOrUndefined} from "util";
import {AdminContextConsumer} from "../pages/Admin";
import {menus} from "./Menus";
import uuid from "uuid/v1"


const styles = theme => ({});



class DrawerMenu extends React.Component {
    state = {};

    render() {

        return (


            <React.Fragment>

                <AdminContextConsumer>
                    {({drawerOpen, onMenuChange}) => (

                        <React.Fragment>
                            {menus.map(menu => {

                                return (
                                    <div key={uuid()}>
                                        {!isNullOrUndefined(menu.title) && drawerOpen ? (
                                            <ListSubheader disableSticky={true}>{menu.title}</ListSubheader>
                                        ) : (!isNullOrUndefined(menu.title) && !drawerOpen &&

                                            <Divider/>
                                        )
                                        }
                                        {
                                            menu.entries.map(entry => {
                                                return (
                                                    <ListItem button onClick={(e) => onMenuChange(entry)} key={entry.primary}>
                                                        <Tooltip title={!isNullOrUndefined(entry.tooltip) ? entry.tooltip : entry.primary}>
                                                            <ListItemIcon>
                                                                {entry.icon}
                                                            </ListItemIcon>
                                                        </Tooltip>
                                                        <ListItemText primary={entry.primary}/>
                                                    </ListItem>

                                                )
                                            })
                                        }
                                    </div>
                                )
                            })}
                        </React.Fragment>

                    )}

                </AdminContextConsumer>
            </React.Fragment>

        )
    }
}

DrawerMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles, {withTheme: true})(DrawerMenu)